import { Component, OnInit, Input } from '@angular/core';
import { PdNavMainItem } from '../nav-main/nav-main-item';


@Component({
  selector: 'app-nav-dropdown',
  templateUrl: './nav-dropdown.component.html',
  styleUrls: ['./nav-dropdown.component.scss']
})
export class NavDropdownComponent implements OnInit {
  @Input() navItem: PdNavMainItem;

  constructor() { }

  ngOnInit() {

  }

}
