import { Component, OnInit } from '@angular/core';
import contactUsPageDataSource from '@data/page-contact-us.json';
import contactInfoDataSource from '@data/contact-info.json';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  content = contactUsPageDataSource.contactUs;
  contactUsInfo = contactInfoDataSource.contactInfo;

  constructor() { }

  ngOnInit() {
  }

}
