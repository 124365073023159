import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-logo',
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent implements OnInit {

  @Input() image: string;
  @Input() slogan: string;

  constructor() { }

  ngOnInit() {
  }

}
