import { Component, OnInit } from '@angular/core';
import productsDataSource from '@data/products.json';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {

  productCategories = productsDataSource.categories;
  selectedCategory = this.productCategories[0];

  constructor() { }

  selectCategory(category) {
    this.selectedCategory = category;
  }

  ngOnInit() {
  }

}
