import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import productCategoriesDataSource from '@data/products.json';
import { PbProductCategoryModel } from 'src/app/components/products/product-category.model';


@Component({
  selector: 'app-product-category-page',
  templateUrl: './product-category-page.component.html',
  styleUrls: ['./product-category-page.component.scss']
})
export class ProductCategoryPageComponent implements OnInit {
  productCategories = productCategoriesDataSource.categories;
  category: PbProductCategoryModel;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      params => {
        const categoryName = params['categoryName'];
        const prodIndex = this.productCategories.findIndex(prod => {
          return prod.linkName.toLowerCase() === categoryName.toLowerCase();
        });
        this.category = this.productCategories[prodIndex];
      });
  }

}
