import { Component, OnInit, Input } from '@angular/core';
import productCategoriesDataSource from '@data/portion-control.json';

@Component({
  selector: 'app-portion-control-banner',
  templateUrl: './portion-control-banner.component.html',
  styleUrls: ['./portion-control-banner.component.scss']
})
export class PortionControlBannerComponent implements OnInit {

  @Input() showLink = false;
  portionControlBanner = productCategoriesDataSource.portionControl.banner;

  constructor() { }

  ngOnInit() {
  }

}
