import { Component, OnInit, Input } from '@angular/core';
import { PdNavMainItem } from './nav-main-item';

@Component({
  selector: 'app-nav-main',
  templateUrl: './nav-main.component.html',
  styleUrls: ['./nav-main.component.scss']
})
export class NavMainComponent implements OnInit {
  @Input() navMenu: PdNavMainItem[];

  constructor() { }

  ngOnInit() {
  }

}
