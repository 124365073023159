import { Component, OnInit, Input } from '@angular/core';
import { PdNavSocialItem } from './nav-social-item';

@Component({
  selector: 'app-nav-social',
  templateUrl: './nav-social.component.html',
  styleUrls: ['./nav-social.component.scss']
})
export class NavSocialComponent implements OnInit {
  @Input() socialNav: PdNavSocialItem[];

  constructor() { }

  ngOnInit() {
  }

}
