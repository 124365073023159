import { Component, OnInit, Input } from '@angular/core';
import contactInfoDataSource from '@data/contact-info.json';
import productsDataSource from '@data/products.json';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
  @Input() websiteName: string;

  contactUsInfo = contactInfoDataSource.contactInfo;
  productCategories = productsDataSource.categories;
  currentYear = new Date().getFullYear();
  logoPath = 'assets/images/MidwestIcon_White.png';

  constructor() {
  }

  ngOnInit() {

  }



}
