import { Component, OnInit, Input } from '@angular/core';
import { PdAddressModel } from './address-model';

@Component({
  selector: 'app-info-address',
  templateUrl: './info-address.component.html',
  styleUrls: ['./info-address.component.scss']
})
export class InfoAddressComponent implements OnInit {
  @Input() address: PdAddressModel;
  @Input() hideIcon = false;

  constructor() { }

  ngOnInit() {
  }

}
