import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PdContactUsModel } from './contact-form.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  @Input() messages;
  @Input() subjectValues: string[];
  @Input() hidePhone = false;
  @Input() hideSubject = false;

  @ViewChild('f') form: NgForm;

  env = environment;
  model = this.createModel();
  isSending = false;

  constructor(private httpClient: HttpClient, private toastr: ToastrService) { }

  ngOnInit() {
  }

  createModel(): PdContactUsModel {
    return this.model = {
      name: '',
      email: '',
      message: '',
      subject: ''
    };
  }

  async onSubmit() {
    let message = `Message: \n${this.model.message} \n\n`;
    if (this.model.subject) {
      message += 'Subject: ' + this.model.subject + '\n';
    }
    if (this.model.company) {
      message += `Company: ${this.model.company} \n`;
    }
    if (this.model.phone) {
      message += 'Phone Number: ' + this.model.phone + '\n';
    }
    message += `\nYour reply to this email will be directly sent to ${this.model.name} at the following email address: ${this.model.email}`;

    await this.sendEmail(message);
  }

  async getEmailRecipients(): Promise<string> {
    const headers = new HttpHeaders({ 'Hostname': window.location.hostname });
    const response = await this.httpClient.get(this.env.contactForm.emailRecepientsUrl, {
      headers: headers,
      observe: 'response'
    }).toPromise();
    return response.body['Contact_Us_Endpoints'].SS;
  }

  async sendEmail(message: string) {
    this.isSending = true;
    const body = {
      senderName: this.model.name,
      senderEmail: this.model.email,
      recipients: await this.getEmailRecipients(),
      hostname: window.location.hostname,
      message: message
    };

    try {
      await this.httpClient.post(this.env.contactForm.sendEmailUrl, body).toPromise();

      this.toastr.success(this.messages.success);
    } catch (error) {
      this.toastr.error(this.messages.error);
      console.log('POST call in error', error);
    }
    // this.toastr.success(this.messages.success);
    this.resetInputs();
    this.isSending = false;
  }

  resetInputs() {
    this.form.reset();
    this.form.resetForm();
  }

}
