import { Component, OnInit, Renderer2, HostListener, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';

import contactInfoDataSource from '@data/contact-info.json';
import navMainDataSource from '@data/nav-main.json';

import { PdNavMainItem } from 'src/app/common/nav/nav-main/nav-main-item';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit {
  navMenu: PdNavMainItem[] = navMainDataSource.navMenu;
  contact: PdNavMainItem;

  contactInfo = contactInfoDataSource.contactInfo;

  isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit() {
    this.contact = this.navMenu.shift();
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart)
    ).subscribe((e: NavigationStart) => {
      this.isCollapsed = true;

    });
  }
}
