import { Component, OnInit, Input } from '@angular/core';
import { PbPhoneModel as PbInfoPhoneModel } from './info-phone-model';

@Component({
  selector: 'app-info-phone',
  templateUrl: './info-phone.component.html',
  styleUrls: ['./info-phone.component.scss']
})
export class InfoPhoneComponent implements OnInit {
  @Input() phone: PbInfoPhoneModel;
  @Input() hideIcon = false;

  constructor() { }

  ngOnInit() {
  }

}
