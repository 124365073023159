import { Component, OnInit, Input } from '@angular/core';
import { PbInfoEmailModel } from './inpho-email-model';

@Component({
  selector: 'app-info-email',
  templateUrl: './info-email.component.html',
  styleUrls: ['./info-email.component.scss']
})
export class InfoEmailComponent implements OnInit {
  @Input() email: PbInfoEmailModel;
  @Input() hideIcon = false;

  constructor() { }

  ngOnInit() {
  }

}
