import { Component, OnInit, Input } from '@angular/core';
import { PbProductCategoryModel } from '../product-category.model';

@Component({
  selector: 'app-category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.scss']
})
export class CategoryHeaderComponent implements OnInit {

  @Input() category: PbProductCategoryModel;

  constructor() { }

  ngOnInit() {
  }

}
