import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ProductCategoryPageComponent } from './pages/product-category-page/product-category-page.component';
import { PortionControlPageComponent } from './pages/portion-control-page/portion-control-page.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

const routes = [
  { path: '', component: HomePageComponent },
  // {
  //   path: 'products',
  //   children: [
  //     { path: ':categoryName', component:  ProductCategoryPageComponent},
  //   ]
  // },
  // { path: 'portion-control', component: PortionControlPageComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: '**', component: HomePageComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
