import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavMainComponent } from './common/nav/nav-main/nav-main.component';
import { NavDropdownComponent } from './common/nav/nav-dropdown/nav-dropdown.component';
import { NavSocialComponent } from './common/nav/nav-social/nav-social.component';
import { NavMainMobileComponent } from './common/nav/nav-main-mobile/nav-main-mobile.component';
import { NavHeaderComponent } from './navigation/nav-header/nav-header.component';
import { NavFooterPodoComponent } from './common/nav/nav-footer-podo/nav-footer-podo.component';
import { NavLogoComponent } from './common/nav/nav-logo/nav-logo.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { InfoPhoneComponent } from './common/contacts/info-phone/info-phone.component';
import { InfoEmailComponent } from './common/contacts/info-email/info-email.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { QuoteComponent } from './components/quote/quote.component';
import { InfoAddressComponent } from './common/contacts/info-address/info-address.component';
import { ProductCategoryPageComponent } from './pages/product-category-page/product-category-page.component';
import { CategoryHeaderComponent } from './components/products/category-header/category-header.component';
import { ProductDisplayerComponent } from './components/products/product-displayer/product-displayer.component';
import { PortionControlBannerComponent } from './components/portion-control-banner/portion-control-banner.component';
import { PreferredSupplierComponent } from './components/products/preferred-supplier/preferred-supplier.component';
import { ProductExtrasComponent } from './components/products/product-extras/product-extras.component';
import { PortionControlPageComponent } from './pages/portion-control-page/portion-control-page.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ContactFormComponent } from './forms/contact-form/contact-form.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMainComponent,
    NavDropdownComponent,
    NavSocialComponent,
    NavMainMobileComponent,
    NavHeaderComponent,
    NavFooterPodoComponent,
    NavLogoComponent,
    NavHeaderComponent,
    InfoPhoneComponent,
    InfoEmailComponent,
    InfoAddressComponent,
    NavSocialComponent,
    NavFooterPodoComponent,
    PageFooterComponent,
    HomePageComponent,
    HomeHeaderComponent,
    QuoteComponent,
    ProductCategoryPageComponent,
    CategoryHeaderComponent,
    ProductDisplayerComponent,
    PortionControlBannerComponent,
    PreferredSupplierComponent,
    ProductExtrasComponent,
    PortionControlPageComponent,
    ContactFormComponent,
    ContactUsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
