import { Component, OnInit } from '@angular/core';
import productCategoriesDataSource from '@data/portion-control.json';

@Component({
  selector: 'app-portion-control-page',
  templateUrl: './portion-control-page.component.html',
  styleUrls: ['./portion-control-page.component.scss']
})
export class PortionControlPageComponent implements OnInit {

  content = productCategoriesDataSource.portionControl;

  constructor() { }

  ngOnInit() {
  }

}
