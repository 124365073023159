import { Component, OnInit, Input } from '@angular/core';
import { PdNavMainItem } from '../nav-main/nav-main-item';

@Component({
  selector: 'app-nav-main-mobile',
  templateUrl: './nav-main-mobile.component.html',
  styleUrls: ['./nav-main-mobile.component.scss']
})
export class NavMainMobileComponent implements OnInit {

  @Input() navMenu: PdNavMainItem[];

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    // this.sidebarService.toggle(false, 'leftMenu');
  }

}
