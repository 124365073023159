import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-footer-podo',
  templateUrl: './nav-footer-podo.component.html',
  styleUrls: ['./nav-footer-podo.component.scss']
})
export class NavFooterPodoComponent implements OnInit {
  @Input() logoPath = 'assets/images/podo_logo.png';

  constructor() { }

  ngOnInit() {
  }

}
