import { Component, OnInit, Input } from '@angular/core';
import { PbPreferredSupplierModel } from './featured-supplier.model';

@Component({
  selector: 'app-preferred-supplier',
  templateUrl: './preferred-supplier.component.html',
  styleUrls: ['./preferred-supplier.component.scss']
})
export class PreferredSupplierComponent implements OnInit {

  @Input() supplier: PbPreferredSupplierModel;

  constructor() { }

  ngOnInit() {
  }

}
