import { Component, OnInit } from '@angular/core';
import homePageDataSource from '@data/page-home.json';
import contactInfoDataSource from '@data/contact-info.json';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  content = homePageDataSource.homePage;
  contactInfo = contactInfoDataSource.contactInfo;

  constructor() { }

  ngOnInit() {
  }

}
