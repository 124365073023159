import { Component, OnInit, Input } from '@angular/core';
import { Quote } from '@angular/compiler';
import { PbQuoteModel } from './quote.model';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  @Input() quote: PbQuoteModel;
  @Input() isHomepage = false;
  backgroundStyle = {};

  constructor() { }

  ngOnInit() {
    if (this.quote.image) {
      this.backgroundStyle['background-image'] = `url(${this.quote.image})`;
    }

  }

}
